import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import pdfGeneratorService from "../../../services/pdfGeneratorService";
import setInitialDate from "../../../utils/date";
import { TitlePage } from "../../../components/titlePage.js";
import CustomDataGrid from "../../../components/dataGrid";
import { usePopup } from "../../../hooks/usePopup.js";
import { Button } from "../../../components/Button";
import { useAuth } from "../../../hooks/useAuth";
import { ConfirmModalWithTable } from "../../../components/Modals/ConfirmModalWithTable.js";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#8bc34a",
    color: "white",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "flex-end",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  input: {
    backgroundColor: "#8bc34a",
    color: "white",
  },
}));

export default function PdfGenerator() {
  const { addPopup } = usePopup();
  const classes = useStyles();
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [routeData, setRouteData] = useState([]);
  const [openAlertModal, setOpenModalAlert] = useState(false);
  const [messageAlertModal, setMessageModalAlert] = useState("");
  const [routeAlertData, setRouteAlertData] = useState([]);
  const [deliveryDate, setdeliveryDate] = useState(setInitialDate());
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const columns = [
    {
      field: "delivery_date",
      headerName: "Data de entrega",
      width: 180,
    },
    {
      field: "route",
      headerName: "Rota",
      width: 120,
    },
    {
      field: "pdf_route_link",
      headerName: "Link para download",
      width: 210,
      renderCell: (params) => {
        if (params.value !== "PDF incompleto, aguarde.") {
          return (
            <a href={params.value} target="_blank" rel="noreferrer">
              Download PDF
            </a>
          );
        } else {
          return "PDF incompleto, aguarde.";
        }
      },
    },
    {
      field: "order_without_invoice",
      headerName: "Pedidos sem NF",
      width: 190,
      renderCell: (params) => (
        <Button
          style={{ margin: "auto" }}
          disabled={loading || params.row.orders_without_invoice.length === 0}
          onClick={() =>
            handleViewOrders(
              params.row.orders_without_invoice,
              params.row.route
            )
          }
        >
          Ver pedidos
        </Button>
      ),
    },
    {
      field: "generate_in",
      headerName: "Gerado em",
      width: 180,
      type: "date",
      renderCell: (params) => {
        const route = params.row.route;
        const routeDataItem = routeData.find((item) => item.route === route);
        const generatedDate = routeDataItem
          ? new Date(routeDataItem.updated).toLocaleString().replace(",", " -")
          : "Data não disponível";
        return generatedDate;
      },
    },
    {
      field: "",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Tooltip title="Esse botão é para emergências em casos de problemas com a automação. Usá-lo não irá acelerar o processo da geração que já é automática.">
          <span>
            <Button
              startIcon={<PictureAsPdfIcon />}
              onClick={() => onClickPdfGenerator(params.row.route)}
              disabled={
                loading || params.row.orders_without_invoice.length === 0
              }
              style={{ margin: "auto" }}
            >
              Gerar
            </Button>
          </span>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    onInit(deliveryDate);
  }, [deliveryDate]);

  useEffect(() => {
    const allInvoicesEmpty = routeData.every(
      (row) => row.orders_without_invoice.length === 0
    );
    setIsButtonEnabled(allInvoicesEmpty);
  }, [routeData]);

  const handleViewOrders = (ordersWithoutInvoice, route) => {
    const updatedOrders = ordersWithoutInvoice.map((order) => ({
      id: order,
      route,
    }));

    setRouteAlertData(updatedOrders);
    handleOpenAlertModal();
  };

  const onInit = (deliveryDate) => {
    setLoading(true);
    pdfGeneratorService
      .getPdfLinks(deliveryDate, token)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error();
        }
        return response.json();
      })
      .then((data) => {
        const sortedData = data.sort(
          (a, b) => new Date(b.updated) - new Date(a.updated)
        );
        setRouteData(sortedData);
      })
      .catch((error) => {
        addPopup({
          type: "error",
          title: "Operação não realizada!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setdeliveryDate(e.target.value);
  };

  const onClickPdfGenerator = async (route) => {
    setLoading(true);
    try {
      await pdfGeneratorService.pdfGenerator(route, deliveryDate, token);
      addPopup({
        type: "success",
        title: "Operação realizada com sucesso!",
      });
      onInit(deliveryDate);
    } catch (err) {
      const errData = err?.response?.data;
      if (errData?.message.includes("ainda não possuem nota")) {
        const updatedOrders = errData?.data.map((order) => ({
          id: order,
          route,
        }));
        setRouteAlertData(updatedOrders);
        setMessageModalAlert(errData?.message);
        handleOpenAlertModal();
        handleOpenAlertModal();
      }

      addPopup({
        type: "error",
        title: errData?.message || "Operação não realizada!",
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickDownloadAllPdfs = () => {
    routeData.forEach((item, index) => {
      setTimeout(() => {
        const a = document.createElement("a");
        a.href = item.pdf_route_link;
        a.download = item.pdf_name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, index * 500); // 1 segundo de atraso entre cada download
    });
  };

  const handleOpenAlertModal = () => {
    setOpenModalAlert(true);
  };

  const handleCloseAlertModal = () => {
    setOpenModalAlert(false);

    onInit(deliveryDate);
  };

  return (
    <div>
      <TitlePage>Gerador de PDF por Rota</TitlePage>
      <p>
        Selecione a data de entrega desejada para consulta. Lembre-se que a
        geração do PDF é AUTOMÁTICA, use o botão de gerar SOMENTE em caso de
        emergências. pois gerar por aqui não irá acelerar o processo.
      </p>
      <form className={classes.container} noValidate>
        <div>
          <TextField
            id="date"
            label="Data de Entrega"
            type="date"
            value={deliveryDate}
            onChange={handleChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Tooltip
            title="Só é possível com todos o PDFs completos."
            placement="top"
          >
            <span>
              <Button
                onClick={onClickDownloadAllPdfs}
                style={{ margin: "10px 5px", marginRight: "50px" }}
                disabled={loading || !isButtonEnabled}
              >
                Baixar todos os PDFs
              </Button>
            </span>
          </Tooltip>
        </div>
      </form>
      <CustomDataGrid rows={routeData} columns={columns} loading={loading} />
      <ConfirmModalWithTable
        handleClose={handleCloseAlertModal}
        confirmText={messageAlertModal}
        subtitle={"Mas não se preocupe em gerar!"}
        legend={
          "Assim que o pedido é faturado, o PDF é gerado automaticamente."
        }
        open={openAlertModal}
        onClose={setOpenModalAlert}
        rows={routeAlertData}
        columns={[
          {
            field: "route",
            headerName: "Rota",
            width: 210,
          },
          {
            field: "id",
            headerName: "Pedidos sem nota",
            width: 207,
          },
        ]}
      />
    </div>
  );
}
